<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 pa-6>
        <v-card outlined>
          <v-layout wrap>
            <v-flex xs12 sm6 md4 px-6 pt-6>
              <v-select
                :items="year"
                v-model="firstgraphyear"
                label="Year"
                outlined
                dense
                @change="graphyyyyyy()"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 mt-8>
              <div id="chart" v-if="flag">
                <apexchart
                  type="line"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 py-6 px-6>
        <v-card outlined>
          <v-layout wrap>
            <v-flex xs12 sm6 md6 lg6 px-6 pt-6>
              <v-select
                @change="graph2()"
                :items="year"
                v-model="secondgraphyear"
                label="Year"
                outlined
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 pa-6>
              <v-autocomplete
                @change="graph2()"
                :items="kinfOfFood"
                v-model="productvalue"
                label="select Item"
                outlined
                item-text="itemName"
                item-value="_id"
                dense
                class="my-0 py-0"
              ></v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 mt-8>
              <div id="chart2" v-if="flag2">
                <apexchart
                  type="bar"
                  height="430"
                  :options="chartOptions2"
                  :series="series2"
                ></apexchart>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
     <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      productvalue: null,
      kinfOfFood: [],
      firstgraphyear: "2022",
      secondgraphyear: "2022",
      year: [
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
      ],
      itemId: null,
      flag: false,
      flag2: false,
      showsnackbar: false,
      msg: null,
      appLoading: false,

      series: [
        {
          name: "Total Cost",
          data: [],
        },
      ],
      series2: [
        {
          name: "Total purchase quantity",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 7, 5],
          curve: "straight",
          dashArray: [0, 8, 5],
        },
        title: {
          text: "Total Cost",
          align: "left",
          class: "nsbold",
          font: "28px",
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              ""
            );
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "March",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val + " (Rs)";
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val + " per session";
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          ],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },

      chartOptions2: {
        colors: ["#FFD341", "#f01f36"],
        chart: {
          type: "bar",
          height: 350,
        },
        title: {
          text: "Total purchase",
          align: "left",
          class: "nsbold",
          font: "28px",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "March",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
    };
  },
  mounted() {
    this.getFoodType();
    this.graphyyyyyy();
    // this.graph2();
  },
  watch: {
    // productvalue() {
    //   var ftype= null
    //   for(let i=0;i<this.kinfOfFood.length;i++) {
    //     if(this.productvalue == this.kinfOfFood[i]._id) {
    //       ftype = this.kinfOfFood[i].quantityType
    //     }
    //   }
    //   this.series2[0].name = "Total purchase quantity in " + ftype
    // }
    // secondgraphyear() {
    //   this.graph2();
    // },
    // itemid() {
    //   this.graph2();
    // },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/count",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.st = response.data;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    graphyyyyyy() {
      this.appLoading = true;
      this.flag = false;
      axios({
        method: "POST",
        url: "dashboard/graph",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          year1: this.firstgraphyear,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.series[0].data = [];
          if (response.data.status == true) {
            this.graph = response.data;
            for (let i = 0; i < response.data.data.length; i++) {
              this.series[0].data.push(response.data.data[i].amount);
            }
            this.flag = true;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    graph2() {
      var ftype = null;
      for (let i = 0; i < this.kinfOfFood.length; i++) {
        if (this.productvalue == this.kinfOfFood[i]._id) {
          ftype = this.kinfOfFood[i].quantityType;
        }
      }
      this.series2[0].name = "Total purchase quantity in " + ftype;

      if (ftype) {
        this.appLoading = true;
        this.flag2 = false;
        axios({
          method: "POST",
          url: "/dashboard/graph/itemwise",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            year1: this.secondgraphyear,
            itemid: this.productvalue,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.series2[0].data = [];
            if (response.data.status == true) {
              this.graph = response.data;
              for (let i = 0; i < response.data.data.length; i++) {
                this.series2[0].data.push(response.data.data[i].quantity);
              }
              this.flag2 = true;
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }

            // this.msg = response.data.msg;

            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      }
    },
    getFoodType() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/item/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            
            this.kinfOfFood = response.data.data;
            this.productvalue = response.data.data[0]._id;
            this.food = response.data.data.quantityType;
            
    this.graph2();

            this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
  },
};
</script> 